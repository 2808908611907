// var stickyHeader = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

// var countUpStats = false;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
var transformHomepageDonationAmountImages = true;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

var lualBodyClasses = ["christmas-lights-appeal", "flowers-of-remembrance-appeal", "shining-stars-appeal"];

// Document ready function
$('document').ready(function () {
  // Search button height
  $('#siteSearch').css('height', $('.mainCallToActionButtons .cta-button:first-of-type').css('height'));
  $('.searchContainer > button').css('height', $('#siteSearch').css('height'));
})

// Thing to make blockquote attributes easier
if (($('body[class*="PostBody"]').length = 1)) {
  var blockquotes = document.querySelectorAll("blockquote");
  for (var i = 0; i < blockquotes.length; i++) {
    if (blockquotes[i].innerHTML.includes("quote-attribute")) {
      blockquotes[i].classList.add("contains-attribution");
    }
  }
}